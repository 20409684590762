import axios from "axios";
import { Rule } from "../components/Content/ClickSelfie/uploadRules";
const apiDomain: string =
  window.location.origin !== "http://localhost:3001"
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3001"
    : "http://localhost:3001";

const getHeaders = (token: string) => {
  return {
    Authorization: "Bearer " + token,
  };
};

export const submitAvatar = async (
  authdata: {
    workspaceId: string;
    circleUserId: string;
    token: string;
  },
  data: {
    dataUrl?: string;
    photoType: string;
  }
) => {
  const url = `${apiDomain}/api/v2/workspaces/${authdata.workspaceId}/users/${authdata.circleUserId}/avatars/photo-avatar`;
  const headers = getHeaders(authdata?.token);
  const result = await axios.post<any>(url, data, { headers });
  return result;
};

export const submitImagetoAutomation = async (data: {
  testCaseName: string;
  imageUrl: string;
  imageName: string;
  testCaseImageGroundTruthDto: { [key: string]: any };
  status: string;
  failedCheck: string | Rule | Rule[];
  userName: string;
  npmVersion: string;
  flag: string;
}) => {
  const url = `${process.env.REACT_APP_SR_API_DOMAIN}api/v1/selfierater/testcaseimage/selfieratingresult`;
  const result = await axios.post<any>(url, data);
  return result;
};

export const getQualityCheckParameters = async (token: string) => {
  const url = `${apiDomain}/api/v2/avatars/selfie-rating-config`;
  const headers = getHeaders(token);
  const result = await axios.get<any>(url, { headers });
  return result;
};
